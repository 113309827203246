import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, Routes, Route, Link } from 'react-router-dom';

import './Menu.css';

import title from '../../Media/menuTitle.svg'

function Menu({ setIsMenuOpen, isMenuOpen }) {
    const navigate = useNavigate()

    return (
                <div className='menu__container' onScroll={(e)=> e.preventDefault()}>
                    <div className='menu__closeBtn' onClick={()=> setIsMenuOpen(false)}></div>
                    <img src={title} alt='menu' className='menu__title' />

                        <ul className='menu__links cls: uk-animation-slide-top' data-uk-scrollspy={`cls: uk-animation-slide-top`}>
                            <NavLink to={'/about'} className={({ isActive }) => isActive ? "active_link" : "active_hover"} onClick={()=> setIsMenuOpen(false)}>
                                <li className='menu__linkElement'>
                                    <div className='mainPage__listIcon active_hover_icon' />
                                    <span>о выставке</span>
                                </li>
                            </NavLink>
                            <a href={'https://tickets.museikino.ru/event/8F11286A8218152C8C2FB8B137A235CB1140E47C/2023-11-30'} target='_blank' rel="noreferrer" className='active_hover' onClick={()=> setIsMenuOpen(false)}>
                                <li className='menu__linkElement'>
                                    <div className='mainPage__listIcon active_hover_icon' />
                                    <span>купить билет</span>
                                </li>
                            </a>
                            <NavLink to={'/zones'} className='active_hover' onClick={()=> setIsMenuOpen(false)}>
                                <li className='menu__linkElement'>
                                    <div className='mainPage__listIcon active_hover_icon' />
                                    <span>Зоны</span>
                                </li>
                            </NavLink>
                           
                            <li className='menu__linkElement active_hover' onClick={() => { navigate('contacts'); setIsMenuOpen(false) }}>
                            <div className='mainPage__listIcon active_hover_icon' />
                                <span>Контакты</span>
                            </li>
                        </ul>

                </div>
    );
}

export default Menu;
