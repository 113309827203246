import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import './Popup.css';
import './Popup_mobile.css';
import Divider from '../Common/Divider';
import CloseBtn from '../Common/CloseBtn';
import api from '../../Utils/Api';

function Popup({ children, onClickAction, isContactOpen, zoneMode, isMainpage }) {
    const [backgroundZone, setBackgroundZone] = useState(undefined)
    const [next, setNext] = useState(undefined)
    const [previous, setPrevious] = useState(undefined)
    const [zones, setZones] = useState(undefined)
    const [isNewSlide, setIsNewSlide] = useState(false)
    let params = useParams();
    let selectedZone = parseInt(params.id)
    // console.log(params)
    const navigate = useNavigate()
    const ref = useRef(null)

    useEffect(() => {
        if (zoneMode)
            api.getZonesNamesList()
                .then((res) => {
                    // console.log(res);
                    setZones(res.data)

                })
                .catch((e) => {
                    console.error(e);
                });

    }, [zoneMode])

    useEffect(() => {
        if (zones) {
            let currentIndex = zones.indexOf(zones.find(zone => zone.id === selectedZone))
            if (currentIndex === 0) {
                setNext(zones[1].id)
                setPrevious(zones[zones.length - 1].id)
            } else if (currentIndex === zones.length - 1) {
                setNext(zones[0].id)
                setPrevious(zones[zones.length - 2].id)
            } else {
                setNext(zones[currentIndex + 1].id)
                setPrevious(zones[currentIndex - 1].id)
            }
        }

    }, [params, zones])

    useEffect(() => {
        if (backgroundZone) {
            setBackgroundZone(undefined)
            return
        }
        if (isNewSlide) {
            const timer = setTimeout(() => { setIsNewSlide(false) }, 100)
            return () => clearTimeout(timer)
        }
        setBackgroundZone(children)
    }, [params, isNewSlide])



    return (
        <>
            {/* {selectedZone === 'mainpage' ?
                <MainPage />
            : null }
             {selectedZone === 'zones' ?
                <Zones />
            : null } */}
            <div className='popup__background' >
                <div className='popup__container' ref={ref}>
                    {(!isNewSlide || backgroundZone) &&
                        <div className='animated'>
                            <Divider />
                          {backgroundZone &&  <div className='popup__actions'>
                                {zoneMode ?
                                    <div className='popup__btns'>
                                        <div className='popup__btn rotated_btn' onClick={() => { navigate(`${isMainpage ? '' : '/zones'}/zone/${previous}`); setIsNewSlide(true) }} />
                                        <div className='popup__btn' onClick={() => { navigate(`${isMainpage ? '' : '/zones'}/zone/${next}`); setIsNewSlide(true) }} />
                                    </div>
                                    :
                                    <div className='popup__icon'></div>}
                                <CloseBtn onClickAction={onClickAction} />
                            </div>}
                            {backgroundZone}
                        </div>
                    }
                </div>
            </div>
            {(isNewSlide || !backgroundZone) &&

                <div className='popup__container_front'>
                    {/* <div className='animated'> */}
                    <Divider />
                    <div className='popup__actions'>
                        {zoneMode ?
                            <div className='popup__btns'>
                                <div className='popup__btn rotated_btn' onClick={() => navigate(`${isMainpage ? '' : '/zones'}/zone/${previous}`)} />
                                <div className='popup__btn' onClick={() => navigate(`${isMainpage ? '' : '/zones'}/zone/${next}`)} />
                            </div>
                            :
                            <div className='popup__icon'></div>}
                        <CloseBtn onClickAction={onClickAction} />
                    </div>
                    {children}
                    {/* </div> */}
                </div>
            }
        </>

    );
}

export default Popup;
