import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './Zone.css';
import './Zone_mobile.css';
import Divider from '../Common/Divider';
import api from '../../Utils/Api';
import { baseUrl } from '../../Utils/Api';

function Zone({ isMainpage }) {
    const [zone, setZone] = useState(undefined)
    const [selectedBtn, setSelectedBtn] = useState(0)
    const [isMobile, setIsMobile] = useState(undefined);
    const [isLoaded, setIsLoaded] = useState(false);

    const navigate = useNavigate()

    let params = useParams();
    let selectedZone = parseInt(params.id)
    let selectedHash = parseInt(params.hash)

    useEffect(() => {
        window.scroll(0, 0)
        api.getZone(selectedZone)
            .then((res) => {
                setZone(res.data.attributes)
                setIsLoaded(true)
            })
            .catch((e) => {
                console.error(e);
            });
    }, [params])

    useEffect(() => {
        if (window.innerWidth <= 1064) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, []);

    useEffect(() => {
        if (selectedHash && isLoaded) {
        const anchor = document.getElementById(`block${selectedHash}`)

            if (anchor) {
                anchor.scrollIntoView()
                // console.log('i got anchor and hash', 'anchor is', anchor, 'hash is', selectedHash)
            } 
            // window.location.href = `#${selectedHash}`
        }
    }, [isLoaded]);



    return (
        zone &&
        <div>
            {zone.Name && <div className='zone__title pad_top_64' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; delay: 100`}>
                {zone.Name}
            </div>}
            <div className='zone__infoBtns pad_top_48'>
                <div className={`zone__infoBtn ${!selectedBtn ? 'zone__infoBtn_active' : ''}`} onClick={() => setSelectedBtn(0)}>информация</div>
                <div className={`zone__infoBtn ${selectedBtn ? 'zone__infoBtn_active' : ''}`} onClick={() => setSelectedBtn(1)}>Расположение</div>
            </div>
            {selectedBtn ?
                <img className='zone__photo pad_bot_60 pad_top_48' src={`${baseUrl}/${zone.Map.data.attributes.url}`} alt='' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; uk-animation-fade; delay: 400`}></img>
                :
                <>
                    <img className='zone__photo pad_bot_60 pad_top_48' src={`${baseUrl}/${zone.Photo.data.attributes.url}`} alt='' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; uk-animation-fade; delay: 400`}></img>
                    <div className='zone__subtitle' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; uk-animation-fade; delay: 400`}>{zone.Title}</div>
                    <div className='zone__description pad_top_36 pad_bot_60' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; uk-animation-fade; delay: 400`}>{zone.Description}</div>
                    <Divider />
                    {zone.Content.length > 0 &&
                        <div className='zone__content '>
                            {zone.Content.map((block, i) => (
                                <section key={i} className={`${isMobile && block.ContentPhoto.length ? 'pad_bot_mobile_24' : ''}`} id={`block${i + 1}`}>
                                    <div className='zone__contentBlock pad_bot_60 pad_top_60' data-uk-scrollspy={`target: > div; cls: uk-animation-slide-bottom-medium; uk-animation-fade; delay: 400`}>
                                        <div className='zone__contentTitle'>{block.Title}</div>
                                        <div className='zone__contentText'>{block.Description}</div>

                                    </div>
                                    {block.ContentPhoto && block.ContentPhoto.map((ph, i) => (
                                        <div className='zone__photoBlock pad_bot_60' key={i} data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; uk-animation-fade; delay: 400`}>
                                            <div className='zone__photoContainer'>
                                                <img className='zone__photoImg' src={`${baseUrl}/${ph.Photo.data.attributes.url}`} alt=''></img>
                                            </div>
                                            <div className='zone__photoTitle'>
                                                {isMobile && <div className='zone__photoTitle_decoration' />}
                                                {ph.Title}
                                            </div>
                                            {ph.Description && <div className='zone__photoDescription' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; uk-animation-fade; delay: 400`}>{ph.Description}</div>}
                                        </div>
                                    ))

                                    }
                                    {isMobile && <div className='' />}
                                    <Divider />

                                </section>
                            ))}

                        </div>}
                    {/* <div className='pad_bot_60'></div> */}
                    {zone.Next &&
                        <>
                            <div className='zone__title zone__title_40 pad_top_24 pad_bot_24' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; uk-animation-fade; delay: 400`}>следующие разделы</div>
                            <div className='zone__nextZones'>
                                {zone.Next.data.map((n, i) => (

                                    <div key={i} className='zone__nextCard' onClick={() => navigate(`${isMainpage ? `/zone/${n.id}` : `/zones/zone/${n.id}`}`)} data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; uk-animation-fade; delay: 400`}>
                                        <div className='zone__nextCardTitle'>{n.attributes.Name}</div>
                                        <img className='zone__nextCardImg' alt='' src={`${baseUrl}/${n.attributes.Photo.data.attributes.url}`} />
                                    </div>

                                ))}
                            </div>
                        </>
                    }
                </>
            }
        </div>


    );
}

export default Zone;
