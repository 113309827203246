import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, Routes, Route } from 'react-router-dom';
import './MainPage.css';
import './MainPage_mobile.css';

import logo from '../../Media/logo.svg'
import title from '../../Media/mainPageTitle.svg'
import subtitle from '../../Media/mainSubtitle.svg'
import gaidai from '../../Media/img.png'

import minCult from '../../Media/logosMainPage/minCult.png'
import mosFilm from '../../Media/logosMainPage/mosFilm.png'
import fond from '../../Media/logosMainPage/fond.png'
import days from '../../Media/logosMainPage/7days.png'
import snob from '../../Media/logosMainPage/snob.png'
import city from '../../Media/logosMainPage/city.png'
import afisha from '../../Media/logosMainPage/afisha.png'
import afishaDark from '../../Media/logosMainPage/afishaDark.png'
import grata from '../../Media/logosMainPage/grata.png'

import Popup from '../Popup/Popup';
import Contacts from '../Contacts/Contacts';
import Zone from '../Zone/Zone';
import About from '../About/About';
import Menu from '../BurgerMenu/Menu';

function MainPage({ zones }) {
    const navigate = useNavigate()
    const [isMobile, setIsMobile] = useState(undefined);
    const [isMenuOpen, setIsMenuOpen] = useState(false);



    useEffect(() => {
        if (window.innerWidth <= 1064) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [window.innerWidth]);

    useEffect(() => {
        window.scrollTo(0, 0)
       
    }, []);


    return (
        <>
            <div className={`mainPage__container ${isMobile && isMenuOpen ? 'lock__scroll' : '' }`}>
               <div className='mainPage__leftPart'>

                    <img src={logo} alt='logo' className='mainPage__mainLogo' onClick={() => navigate('/', { replace: true })}></img>
                    { !isMenuOpen && 
                    <ul title="Зоны" className='mainPage__zones'>
                        {zones.map((zone, index) => (
                            <li className='mainPage__listElement active_hover' key={index} onClick={() => navigate(`zone/${zone.id}`)}>
                                <div className='mainPage__listIcon active_hover_icon' />
                                <span>{zone.attributes.Name}</span>
                            </li>
                        ))}

                    </ul>}
                </div>
                <div className='mainPage__rightPart'>
                    <nav>
                        <ul className='mainPage__links cls: uk-animation-slide-top' data-uk-scrollspy={`cls: uk-animation-slide-top`}>
                            {/* <div style={{backgroundColor: '#FD4F57', width: 20, height: 20, position: 'absolute', top: 0, left: '0'}}></div> */}

                            <NavLink to={'/about'} className={({ isActive }) => isActive ? "active_link" : "active_hover"}>
                                <li className='mainPage__linkElement'>
                                    <div className='mainPage__listIcon active_hover_icon' />
                                    <span>о выставке</span>
                                </li>
                            </NavLink>
                            <NavLink to={'/zones'} className='active_hover'>
                                <li className='mainPage__linkElement'>
                                    <div className='mainPage__listIcon active_hover_icon' />
                                    <span>Зоны</span>
                                </li>
                            </NavLink>
                            <a href={'https://tickets.museikino.ru/event/8F11286A8218152C8C2FB8B137A235CB1140E47C/2023-11-30'} target='_blank' rel="noreferrer" className='active_hover'>
                                <li className='mainPage__linkElement'>
                                    <div className='mainPage__listIcon active_hover_icon' />
                                    <span>купить билет</span>
                                </li>
                            </a>
                            {/* <NavLink to={'/contacts'} className='margin_left_auto active_hover'> */}
                            <li className='mainPage__linkElement margin_left_auto active_hover' onClick={() => {navigate('contacts') }}>
                                <span>Контакты</span>
                            </li>
                            {/* </NavLink> */}
                        </ul>
                    </nav>
               {!isMobile && <div className='mainPage__logos'>
                    <img src={minCult} alt='logo' className='mainPage__logo' style={{ width: '44%' }}></img>
                    <img src={mosFilm} alt='logo' className='mainPage__logo' style={{ width: '30.5%' }}></img>
                    <img src={fond} alt='logo' className='mainPage__logo' style={{ width: '89%' }}></img>
                    <img src={days} alt='logo' className='mainPage__logo' style={{ width: '52%' }}></img>
                    <img src={snob} alt='logo' className='mainPage__logo' style={{ width: '37.2%' }}></img>
                    <img src={afisha} alt='logo' className='mainPage__logo' style={{ width: '48.6%' }}></img>
                    <img src={city} alt='logo' className='mainPage__logo' style={{ width: '36.6%' }}></img>
                    <img src={grata} alt='logo' className='mainPage__logo' style={{ width: '73.4%' }}></img>
                </div>}
                    {isMobile &&  <img src={gaidai} alt='Gaidai' className='mainPage__image'></img>}
                </div>
                <div className='mainPage__titles'>
                    <img src={title} alt='title' className='mainPage__title'></img>
                    <img src={subtitle} alt='subtitle' className='mainPage__subtitle'></img>

                </div>
              {!isMobile &&  <img src={gaidai} alt='Gaidai' className='mainPage__image'></img>}
              {isMobile && 
              <>
             {!isMenuOpen && <div className='mainPage__logos'>
                    <img src={minCult} alt='logo' className='mainPage__logo' style={{ width: '18%' }}></img>
                    <img src={mosFilm} alt='logo' className='mainPage__logo' style={{ width: '12%' }}></img>
                    <img src={fond} alt='logo' className='mainPage__logo' style={{ width: 'calc(25% - 11.3px)' }}></img>
                    <img src={grata} alt='logo' className='mainPage__logo' style={{ width: 'calc(25% - 11.3px)'}}></img>
                    <img src={days} alt='logo' className='mainPage__logo' style={{ width: 'calc(22% - 11.3px)' }}></img>
                    <img src={snob} alt='logo' className='mainPage__logo' style={{ width: 'calc(22% - 11.3px)' }}></img>
                    <img src={afishaDark} alt='logo' className='mainPage__logo' style={{ width: 'calc(22% - 11.3px)' }}></img>
                    <img src={city} alt='logo' className='mainPage__logo' style={{ width: 'calc(22% - 11.3px)' }}></img>
                </div>}
                <div className='menu' onClick={() => setIsMenuOpen(true)}></div>
                {isMenuOpen && 
                <Menu setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
                
            }
                </>
                }
            </div>
            <Routes>
                <Route path="contacts"
                    element={<Popup onClickAction={() => {navigate('') }}> <Contacts /></Popup>} />
                <Route path="zone/:id"
                    element={<Popup onClickAction={() => {navigate('') }} zoneMode isMainpage><Zone isMainpage /> </Popup>} />
                     <Route path="zone/:id/:hash"
                    element={<Popup onClickAction={() => {navigate('') }} zoneMode isMainpage><Zone isMainpage /> </Popup>} />
                <Route path="about"
                    element={<Popup onClickAction={() => {navigate('') }} isMainpage><About isMainpage /> </Popup>} />
            </Routes>
        </>
    );
}

export default MainPage;
