import React, { useState, useEffect } from 'react';
import './Contacts.css';
import './Contacts_mobile.css';
import cover from '../../Media/cover.png'
import Divider from '../Common/Divider';

import vk from '../../Media/vk.svg'
import tg from '../../Media/tg.svg'

function Contacts() {

    return (

        <div >
            <h1 className='pad_top_28'>Контакты</h1>
            <img src={cover} alt='museum' className='contacts__image pad_bot_48 pad_top_48 pad_bot_28 pad_top_28' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; delay: 100`} />
            <h5 className='pad_bot_60 pad_bot_28' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; delay: 100`}>Государственный центральный музей кино находится на ВДНХ в павильоне № 36</h5>
            <div className='contacts__media pad_bot_28 pad_bot_60'>
            <a href='https://vk.com/cinemuseum' target='_blank' rel='noreferrer'><img src={vk} alt='' className='contacts__logo'></img></a>
            <a href='https://t.me/museikino' target='_blank' rel='noreferrer'><img src={tg} alt='' className='contacts__logo'></img></a>
            </div>
            <Divider />
            <div className='pad_bot_60 pad_top_60 pad_top_28'>
                <div className='contacts__infoBlock d_flex_row' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; delay: 100`}>
                    <p className='contacts__capture'>Часы работы</p>
                    <div>
                        <p className='contacts__description'>Вт, Ср, Чт — с&nbsp;10:00&nbsp;до&nbsp;21:00</p>
                        <p className='contacts__description'>Пт, Сб, Вс — с&nbsp;10:00&nbsp;до&nbsp;22:00</p>
                        <p className='contacts__description'>Выходной день — понедельник</p>
                    </div>
                </div>
                <div className='contacts__infoBlock d_flex_row' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; delay: 100`}>
                    <p className='contacts__capture'>Адрес </p>
                    <div>
                        <p className='contacts__description'>Россия, 129223, г. Москва, проспект Мира, 119, строение&nbsp;36.</p>
                    </div>
                </div>
                <div className='contacts__infoBlock d_flex_row' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; delay: 100`}>
                    <p className='contacts__capture'>телефон</p>
                    <div>
                        <p className='contacts__description'>+7 (495) 150-36-00 доб. 111</p>
                    </div>
                </div>
                <div className='contacts__infoBlock d_flex_row' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; delay: 100`}>
                    <p className='contacts__capture'>E-mail</p>
                    <div>
                        <a href="mailto:museikino@museikino.ru" className='contacts__description accented_text'>museikino@museikino.ru</a>
                    </div>
                </div>

            </div>
            <h6>Как добраться</h6>

            <div className='pad_bot_60 pad_top_60 pad_top_28' data-uk-scrollspy={`target: > div; cls: uk-animation-slide-bottom-medium; delay: 100`}>
                <div className='contacts__infoBlock'>
                    <p className='contacts__capture text_nowrap'>«Социальное такси»</p>
                    <div>
                        <p className='contacts__description mar_bot_28'>Для инвалидов и маломобильных<br />граждан по ВДНХ.<br />Режим работы с&nbsp;9:00&nbsp;до&nbsp;21:00.<br />Телефон: +7 (910) 450-19-98.</p>
                        <a href={'https://vdnh.ru/visitors/'} target='_blank' rel="noreferrer" className='contacts__description accented_text underlined'>Подробно на странице</a>
                    </div>
                </div>
                <div className='contacts__infoBlock'>
                    <p className='contacts__capture text_nowrap'>Личный транспорт </p>
                    <div>
                        <p className='contacts__description mar_bot_28'>Для автовладельцев работает многоуровневая парковка по адресу Хованская улица, 18,<br />в&nbsp;непосредственной близости от&nbsp;КПП&nbsp;"Хованский".</p>
                        <span className='contacts__description'>Подробно на сайте <a href={'https://vdnh.ru/news/51949/'} target='_blank' className='accented_text underlined' rel="noreferrer">vdnh.ru</a></span>
                    </div>
                </div>
                <div className='contacts__infoBlock'>
                    <p className='contacts__capture text_nowrap'>На электробусе</p>
                    <div>
                        <p className='contacts__description mar_bot_28'>Электробусы курсируют по маршруту круглый год, по графику с&nbsp;09:00&nbsp;до&nbsp;21:00.</p>
                        <a href={'https://vdnh.ru/visitors/'} target='_blank' rel="noreferrer" className='contacts__description accented_text underlined'>Подробно на странице</a>
                    </div>
                </div>

            </div>

        </div>

    );
}

export default Contacts;
