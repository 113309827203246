import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import './App.css';
import './App_mobile.css';
import MainPage from '../MainPage/MainPage';
import Zones from '../Zones/Zones';
import api from '../../Utils/Api';



function App() {
  const [zones, setZones] = useState([])
  const [isContactOpen, setIsContactOpen] = useState(false)

  useEffect(() => {
    api.getZonesNamesList()
      .then((res) => {
        // console.log(res);
        setZones(res.data)

      })
      .catch((e) => {
        console.error(e);
      });

  }, [])

  // console.log(isContactOpen)
  return (

    <>
      <div className='app'>
        <Routes>
          <Route path='/*'
            element={
              <MainPage zones={zones} setIsContactOpen={setIsContactOpen} />
            } />
          <Route path='/zones/*'
            element={
              <Zones zones={zones} setIsContactOpen={setIsContactOpen} isContactOpen={isContactOpen} />
            } >
          </Route>
        </Routes>
      </div>
    </>

  );
}

export default App;
