import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate, Route, Routes } from 'react-router-dom';
import './Zones.css';
import './Zones_mobile.css';

import logo from '../../Media/logo.svg'
import title from '../../Media/zonesTitle.svg'
import Popup from '../Popup/Popup';
import Contacts from '../Contacts/Contacts';
import Zone from '../Zone/Zone';
import About from '../About/About';
import Menu from '../BurgerMenu/Menu';
import map from '../../Media/bg4x.png'

function Zones({ zones }) {
    const navigate = useNavigate()
    const [isMobile, setIsMobile] = useState(undefined);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 1064) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [window.innerWidth]);



    return (
        <>
            <div className='zones__container'>
                <div className='zones__header'>
                    <img src={logo} alt='logo' className='mainPage__logo' onClick={() => navigate('/', { replace: true })}></img>
                    {isMobile &&
                        <>
                            <div className='menu' onClick={() => setIsMenuOpen(true)}></div>
                            {isMenuOpen &&
                                <Menu setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />}
                        </>
                    }
                    <nav className='zones__headerNav'>
                        <ul className='mainPage__links'>
                            {/* <div style={{backgroundColor: '#FD4F57', width: 20, height: 20, position: 'absolute', top: 0, left: '0'}}></div> */}

                            <NavLink to={'about'} className='active_hover'>
                                <li className='mainPage__linkElement'>
                                    <div className='mainPage__listIcon active_hover_icon' />
                                    <span>о выставке</span>
                                </li>
                            </NavLink>
                            <NavLink to={'/zones'} className='active_hover'>
                                <li className='mainPage__linkElement'>
                                    <div className='mainPage__listIcon active_hover_icon' />
                                    <span>Зоны</span>
                                </li>
                            </NavLink>
                            <a href='https://tickets.museikino.ru/event/8F11286A8218152C8C2FB8B137A235CB1140E47C/2023-11-30' rel='noreferrer' target="_blank" className='active_hover'>
                                <li className='mainPage__linkElement'>
                                    <div className='mainPage__listIcon active_hover_icon' />
                                    <span>купить билет</span>
                                </li>
                            </a>
                            <li className='mainPage__linkElement margin_left_auto active_hover' onClick={() => { navigate('contacts') }}>
                                <span>Контакты</span>
                            </li>

                        </ul>
                    </nav>
                </div>
                <img src={title} alt='title' className='zones__title'></img>
                <nav className='zones__navBar'>
                    <ul className={`${isMobile ? '' : 'zones__ul'}`}>
                        {zones.map((zone, index) => (

                            <li className='zones__linkElement active_hover' onClick={() => navigate(`zone/${zone.id}`)} key={index}>
                                <div className='mainPage__listIcon active_hover_icon' />
                                <span>{zone.attributes.Name}</span>
                            </li>

                        ))}

                    </ul>
                    {!isMobile && <img src={map} alt='title' className='zones__map'></img>}
                </nav>

            </div>
            <Routes>
                <Route path="contacts"
                    element={<Popup onClickAction={() => { navigate('/zones') }}> <Contacts /></Popup>} />
                <Route path="zone/:id"
                    element={<Popup onClickAction={() => { navigate('/zones') }} zoneMode> <Zone /></Popup>} />
                <Route path="about"
                    element={<Popup onClickAction={() => { navigate('/zones') }} ><About /> </Popup>} />
            </Routes>
        </>
    );
}

export default Zones;
