
// const mainUrl = 'http://23.111.108.220:8080/backend'
export const baseUrl = 'https://www.gaidai100.ru/backend'

class Api {
  constructor(options) {
    this._address = options.baseUrl;
    this._headers = options.headers;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(`${res.status}`);
  }

  getZonesNamesList() {
    return fetch(`${this._address}/zones?fields[0]=Name&sort[0]=Position`, {
      method: 'GET',
      headers: this._headers,
    })
      .then(this._checkResponse)
  }
  getZone(id) {
    return fetch(`${this._address}/zones/${id}/?populate[0]=Photo&populate[1]=Content.ContentPhoto.Photo&populate[2]=Next.Photo&populate[3]=Map`, {
      method: 'GET',
      headers: this._headers,
    })
      .then(this._checkResponse)
  }

}
const api = new Api({
  baseUrl: `${baseUrl}/api`,
  headers: {
    'Accept': '*/*',
    'Content-Type': 'application/json',
  },
})
export default api;
