import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './About.css';
import './About_mobile.css';
import cover from '../../Media/AboutImage.png'
import Divider from '../Common/Divider';
import api from '../../Utils/Api';

import minCult from '../../Media/logos/minCult.png'
import mosFilm from '../../Media/logos/mosFilm.png'
import fond from '../../Media/logos/fond.png'
import grata from '../../Media/logos/grata.png'
import days from '../../Media/logos/7days.png'
import snob from '../../Media/logos/snob.png'
import afisha from '../../Media/logos/afisha.png'
import city from '../../Media/logos/city.png'
import sections from '../../Media/expoSections.svg'
import girl from '../../Media/emojiGirl.png'
import boy from '../../Media/emojuBoy.png'

import { baseUrl } from '../../Utils/Api';

function About({ isMainpage }) {
    const [zones, setZones] = useState([])
    const [logos, setLogos] = useState([minCult, mosFilm, fond, grata, days, snob, afisha, city])
    const navigate = useNavigate()
    const [isMobile, setIsMobile] = useState(undefined);

    useEffect(() => {
        Promise.all([
            api.getZone(4),
            api.getZone(3)
        ])

            .then(([first, second]) => {
                let zones = []
                zones.push(first.data)
                zones.push(second.data)
                setZones(zones)

            })
            .catch((e) => {
                console.error(e);
            });

    }, [])
    useEffect(() => {
        if (window.innerWidth <= 1064) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, []);

    return (

        <div data-uk-scrollspy={`target: > *; cls: uk-animation-slide-bottom-medium; delay: 100`}>
            <h1 className='pad_bot_48 pad_top_60'>о выставке</h1>
            <img src={cover} alt='museum' className='contacts__image' />
            <div className='about__logos pad_top_64 pad_bot_60'>
                {logos.map((logo, index) => (
                    index > 0 ?
                        <>
                            <div className='about__logoDivider' />
                            <img className='about__logo' src={logo} key={index} alt='logo' />
                        </>
                        : <img className='about__logo' src={logo} key={index} alt='logo' />
                ))

                }
            </div>

            <h5>Выставочный проект «НАШИ ЛЮДИ. Вселенная персонажей Леонида Гайдая» предлагает взглянуть по-новому на&nbsp;старых любимцев публики, многие из&nbsp;которых давно стали фольклорными персонажами.
            Выставка состоит из&nbsp;нескольких разделов, каждый из&nbsp;которых рассказывает о&nbsp;гайдаевских персонажах одного толка – очевидно, что&nbsp;типажи и&nbsp;маски перемещались из&nbsp;фильма в&nbsp;фильм.</h5>
            <p className={`pad_top_36 ${isMobile ? 'pad_bot_36' : 'pad_bot_60'} f_s_20 about__text `}>Мы предлагаем взглянуть на&nbsp;основных гайдаевских героев как&nbsp;на&nbsp;неожиданный социологический, исторический ресурс: кто&nbsp;же были «наши люди»? Что нас с&nbsp;ними связывает? В&nbsp;чем мы на&nbsp;них похожи, а&nbsp;в&nbsp;чем нет?</p>
            <Divider />
            <div className={``}>

                <div className={`about__quoteBlock ${isMobile ? 'pad_top_36' : 'pad_top_60'}`} data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; uk-animation-fade; delay: 400`}>

                    <div className='about__quote' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; uk-animation-fade; delay: 400`}>
                        «Русского человека невозможно представить вне&nbsp;фильмов Гайдая. Это наш культурный код.
                        Леонид Гайдай – режиссер, который выработал свой
                        узнаваемый авторский стиль. Работа с&nbsp;актерами, музыкой, мизансценами и&nbsp;монтажом
                        в&nbsp;его&nbsp;исполнении настолько филигранна, что мы&nbsp;с&nbsp;удовольствием пересматриваем его фильмы год&nbsp;за&nbsp;годом, и,&nbsp;кажется, 
                        никогда от&nbsp;них не&nbsp;устанем. Его кино – кладезь незабываемых цитат, острот, житейской мудрости, и&nbsp;на&nbsp;нашей выставке мы&nbsp;предлагаем
                        познакомиться с&nbsp;их&nbsp;носителями поближе.
                        Работа над этими смешными и&nbsp;симпатичными героями гораздо сложнее и&nbsp;глубже, чем&nbsp;кажется на&nbsp;первый взгляд».
                    </div>
                    <div className='about__quoteTitle'>
                        Наш культурный код
                    </div>
                    <div className='author__container' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; uk-animation-fade; delay: 400`}>
                        <div>
                            <img src={girl} alt='' className='author__photo' />
                        </div>
                        <div className='author__titles'>
                            <div className='author__name'>Лариса Вячеславовна Баженова</div>
                            <div className='author__job'>Директор Музея кино</div>
                        </div>
                    </div>
                </div>
                <div className={`about__quoteBlock ${isMobile ? 'pad_top_36' : 'pad_top_60'}`} data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; uk-animation-fade; delay: 400`}>

                    <div className='about__quote' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; uk-animation-fade; delay: 400`}>
                        «Когда речь заходит о&nbsp;народных режиссёрах, таких как&nbsp;Леонид Гайдай, всегда хочется разобраться в&nbsp;самом феномене их&nbsp;«народности».
                        Мы общаемся друг с&nbsp;другом обрывками из&nbsp;культовых цитат, по&nbsp;телевизору постоянно мелькают знакомые герои, то&nbsp;и&nbsp;дело можно услышать
                        выученные наизусть строки песен. С&nbsp;течением времени это начинает восприниматься как&nbsp;данность, как&nbsp;неотъемлемая часть нашего культурного
                        достояния. В&nbsp;выставке мы&nbsp;постарались объединить и&nbsp;искреннюю любовь к&nbsp;мастеру, и&nbsp;беспристрастную оценку успеха. Ведь почти каждая
                        гайдаевская комедия — это зеркало эпохи. Всякий по-доброму комичный типаж — будь то бдительный управдом или элегантный аферист —
                        отражал ту&nbsp;или&nbsp;иную грань советского общества. Нашего общества, в&nbsp;том числе. А&nbsp;потому фильмы Леонида Иовича, как&nbsp;и&nbsp;любые великие фильмы
                        вообще, дают нам не&nbsp;просто посмеяться или&nbsp;поплакать, но&nbsp;и&nbsp;узнать друг друга получше».
                    </div>
                    <div className='about__quoteTitle'>
                        Феномен «народности»
                    </div>
                    <div className='author__container' data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; uk-animation-fade; delay: 400`}>
                        <div>
                            <img src={boy} alt='' className='author__photo' />
                        </div>
                        <div className='author__titles'>
                            <div className='author__name'>Никита Котик</div>
                            <div className='author__job'>Куратор выставки</div>
                        </div>
                    </div>
                </div>

                {!isMobile && <div className='pad_top_60 pad_bot_60' style={{ height: '24px' }} />}
                {isMobile && <div className='pad_bot_60' />}
                <Divider />

            </div>
            {zones &&
                <>
                    <img src={sections} alt='sections' className='about__title'></img>

                    <div className='zone__nextZones'>
                        {zones.map((n, i) => (
                            <div className='zone__nextCard' key={i} onClick={() => navigate(`${isMainpage ? `/zone/${n.id}` : `/zones/zone/${n.id}`}`)}>
                                <div className='zone__nextCardTitle'>{n.attributes.Name}</div>
                                <img className='zone__nextCardImg' alt='' src={`${baseUrl}${n.attributes.Photo.data.attributes.url}`} />
                            </div>
                        ))}
                    </div>
                </>}


        </div>

    );
}

export default About;
